import React, { useState } from 'react';
import classNames from 'classnames';
import logo from './logo.svg';
import './App.scss';


function App() {
  const [showQuestion, setQuestion] = useState(false);
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Elyse Code School <span role="img" aria-label="female programmer">👩🏻‍💻</span></h1>
      </header>

      <section className="curriculums">
        <h2>Courses</h2>

        <article className="course">
          <header><span className="course-badge">new</span>Full Stack Development Fundamentals</header>
          <article>
            <p>An introduction to present day programming ecosystems. Explains various aspects involved in full stack development before introducing Elyse into the world of frontend web development.</p>
            <p>By the end of this course, Elyse will be expected to have a general idea on how information travels between apps, websites, and servers. Elyse will also be able to make a simple website using HTML, CSS, frontend Javascript and consume data from simple APIs.</p>
          </article>
          <section className="chapters">
            <h3>Chapters</h3>
            <section className="chapter">
              <header>How the Internet Works?</header>
              <article>
                <p>A brief introduction to the present day Internet.</p>
                <p>This chapter gives an overview on the core technologies that brings the Internet to live. At the end of this chapter, Elyse will know how data are requested, generated, and make their way back to her. Elyse will also be able to describe and troubleshoot her way around Internet based applications.</p>
              </article>
            </section>
            <section className="chapter">
              <header>Introduction to HTML</header>
              <article>
                <p>This chapter covers the fundamentals of HTML.</p>
                <p>By the end of this chapter, Elyse will be able to make a very simple web site that can be viewed on her computer and published on the Internet.</p>
              </article>
            </section>
            <section className="chapter">
              <header>Introduction to CSS</header>
              <article>
                <p>This chapter covers the styling of web pages using Cascading Style Sheets (CSS).</p>
                <p>CSS is a language that describes the style of an HTML document. CSS describes how HTML elements should be displayed.</p>
                <p>After this chapter, Elyse will be able to style a simple web site, making it nice and presentable.</p>
              </article>
            </section>
            <section className="chapter">
              <header>Introduction to Git</header>
              <article>
                <p>Git is a popular version control system that helps developers to control their source code as their project grow in size and complexity.</p>
                <p>Elyse will be exposed to GitHub and Bitbucket briefly in this chapter.</p>
                <p>At the completion of this chapter, Elyse will be able to start utilising Git in her work to commit new file updates and use it in a simple continuous deployment (CD) workflow to deploy her projects online.</p>
              </article>
            </section>
            <section className="chapter">
              <header>Website Workshop</header>
              <article>
                <p>This hands-on chapter allows Elyse to start working on a functional website utilising all the technologies covered in the previous chapters (HTML, CSS, and Git).</p>
              </article>
            </section>
            <section className="chapter">
              <header>Introduction to Javascript</header>
              <article>
                <p>Javascript is a scripting language that runs on a browser. It adds interactivity to web pages as it be used to update and change both HTML and CSS on the fly.</p>
                <p>By the end of this chapter, Elyse will be able to write simple program that runs in a browser.</p>
              </article>
            </section>
            <section className="chapter">
              <header>Javascript Libraries</header>
              <article>
                <p>A quick introduction to the world of open source technologies.</p>
                <p>Elyse will also be exposed to package managing systems like NPM, Yarn, and Bower.js.</p>
                <p>By the end of this chapter, Elyse will be able to add popular Javascript libraries like jQuery and Moment.js in the website built in previous chapter.</p>
              </article>
            </section>
            <section className="chapter">
              <header>Advance CSS - SASS</header>
              <article>
                <p>CSS preprocessors allow better organisation and structuring of CSS when used in a complex web project. SASS is one of the most popular CSS preprocessor.</p>
                <p>By the end of this chapter, Elyse will be able to convert the CSS used in her existing website into SASS.</p>
              </article>
            </section>
            <section className="chapter">
              <header>Advance CSS - CSS Animations</header>
              <article>
                <p>While Javascript can be used to animate elements on a website, it is often resource inefficient and cumbersome. This chapter expose Elyse to the world of CSS animations.</p>
                <p>By the end of this chapter, Elyse will be able to add event driven animations on her website powered by CSS.</p>
              </article>
            </section>
            <section className="chapter">
              <header>Introduction to Responsive Web Development</header>
              <article>
                <p>Responsive web design is the approach that suggests that design and development should respond to the user’s behavior and environment based on screen size, platform and orientation. Responsive web development allows website to have layouts that rearrange themselves based on available screen sizes.</p>
                <p>At the end of this chapter, Elyse will be able to optimise websites to have different layouts when they are loaded on different devices.</p>
              </article>
            </section>
            <section className="chapter">
              <header>Advance CSS - Flexbox</header>
              <article>
                <p>Flexbox an advanced CSS layout technique that can be used to ease content layout and responsive web development.</p>
                <p>By the end of this chapter, Elyse will be able to use flexbox to create fluid content lists that are optimised based on device screen sizes.</p>
              </article>
            </section>
            <section className="chapter">
              <header>Introduction to Styling Frameworks</header>
              <article>
                <p>Frontend styling frameworks can be used in web development to speed up initial CSS stylings.</p>
                <p>In this chapter, Elyse will be exposed to the world of frontend styling libraries like <a href="https://getbootstrap.com/" target="_blank" rel="nofollow noopener noreferrer">Bootstrap</a>, <a href="https://get.foundation/" target="_blank" rel="nofollow noopener noreferrer">Foundation</a>, and <a href="https://material.io" target="_blank" rel="nofollow noopener noreferrer">Material Design</a>.</p>
              </article>
            </section>
            <section className="chapter">
              <header>Git Advanced</header>
              <article>
                <p>This chapter covers more advanced Git usages like the concept of branches, pull requests, merging, and gitflows. These skills are useful for working on projects with other developers in team settings.</p>
              </article>
            </section>
            <section className="chapter">
              <header>Introduction to APIs</header>
              <article>
                <p>Application programming interface (API) is one of the most important concept of modern day Internet. It allows different applications to communicate with each other.</p>
                <p>By the end of this chapter, Elyse will be able to request data from various APIs and render results on her website.</p>
              </article>
            </section>
            <section className="chapter">
              <header>Introduction to Javascript Frameworks</header>
              <article>
                <p>A preview into advanced Javascript frameworks that can be used to create web applications.</p>
                <p>In this chapter, Elyse will be introduced briefly to <a target="_blank" rel="nofollow noopener noreferrer" href="https://reactjs.org/">React.js</a>, <a target="_blank" rel="nofollow noopener noreferrer" href="https://angular.io/">Angular</a>, and <a target="_blank" rel="nofollow noopener noreferrer" href="https://emberjs.com/">Ember.js</a>. She will also be teased with more high level frameworks like <a target="_blank" rel="nofollow noopener noreferrer" href="https://nextjs.org/">Next.js</a> and <a target="_blank" rel="nofollow noopener noreferrer" href="https://www.gatsbyjs.org/">Gatsby</a>.</p>
              </article>
            </section>
          </section>
        </article>

        <article className="course coming-soon">
          <header><span className="course-badge">coming soon</span>Introduction to Backend Programming</header>
          <article>
            <p>A quick introduction into the world of server-side (backend) development with popular frameworks like PHP, Python, and Node.js.</p>
          </article>
        </article>

        <article className="course coming-soon">
          <header><span className="course-badge">coming soon</span>Web Application Development with React.js</header>
          <article>
            <p>Course Prerequisites - Elyse must have completed the following courses before commencing this course:</p>
            <ul>
              <li>Full Stack Development Fundamentals</li>
              <li>Introduction to Backend Programming</li>
            </ul>
            <p>Deep dive into the world of React.js. Develop a functional web application using React.js and Node.js.</p>
            <p>Also explores the world of Progressive Web App (PWA).</p>
          </article>
        </article>

        <article className="course coming-soon">
          <header><span className="course-badge">coming soon</span>Mobile Development - Hybrid</header>
          <article>
            <p>Course Prerequisites - Elyse must have completed the following courses before commencing this course:</p>
            <ul>
              <li>Full Stack Development Fundamentals</li>
              <li>Introduction to Backend Programming</li>
              <li>Web Application Development with React.js</li>
            </ul>
            <p>Explores the world of mobile app development by bringing web development skills over to mobile platform using Hybrid technologies.</p>
          </article>
        </article>

        <article className="course coming-soon">
          <header><span className="course-badge">coming soon</span>Mobile Development - iOS</header>
          <article>
            <p></p>
          </article>
        </article>

        <article className="course coming-soon">
          <header><span className="course-badge">coming soon</span>Mobile Development - Android</header>
          <article>
            <p></p>
          </article>
        </article>

      </section>


      <footer>
        <img src="/speech-bubble.png" alt="question" className={classNames("question", {show: showQuestion})}/>
        <img src="/logo512.png" alt="footer badge" onClick={()=>{setQuestion(!showQuestion);}}/>
      </footer>

      <div id="sponsorBadge"><span>&lt;</span>Sponsored by <a href="https://bbi.io" target="_blank" rel="noopener noreferrer"><img src="https://static01.bbi.io/logos/bbi_logo_black.png" alt="BBI - Breaking Bad Interactive" title="BBI"/></a>&nbsp;<span>/&gt;</span></div>
    </div>
  );
}

export default App;
